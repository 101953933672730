.ScrollContainer {
	margin-top:2vh;
}

.ScrollContainer::-webkit-scrollbar {
	width: 6px;
	opacity: 0 !important;
}

.ScrollContainer::-webkit-scrollbar-track {
	opacity: 0 !important;
}

.ScrollContainer::-webkit-scrollbar-thumb {
	background-color: rgba(40, 50, 57, 0.6) !important;
	border-radius: 5px;
}

.ScrollContainer > .GroupCard:first-child {
	margin-top: 0px !important;
}