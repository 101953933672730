@use "@material/icon-button/" as icon-button;
@use "@material/icon-button/mdc-icon-button";


.mdc-icon-button__delete {
    @include icon-button.ink-color(#FF1427);
};

.mdc-icon-button__edit {
    @include icon-button.ink-color(#4DFF00);
};

.mdc-icon-button__detail {
    @include icon-button.ink-color(#000000);
};