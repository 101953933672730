@import 'antd/dist/antd.css';
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import '~bootuseful/bootuseful.min.css';

$primary-color: #2A3239;

@font-face {
    font-family: 'MetropolisBold';
    src: url("../fonts/MetropolisBold.otf") format("opentype");
}

@font-face {
    font-family: 'MetropolisRegular';
    src: url("../fonts/MetropolisRegular.otf") format("opentype");
}

@font-face {
    font-family: 'MetropolisSemiBold';
    src: url("../fonts/MetropolisSemiBold.otf") format("opentype");
}


* {
    font-family: 'MetropolisSemiBold', serif;
}

#sider,  .ant-drawer-body {
    background-color: $primary-color;
    
    .logo {
        background: $primary-color;
        text-align: center;
        box-shadow: 0px 1px 2px #000000A8;
        margin-bottom: 2px;
        padding: 16px;
        font: normal normal bold Source Sans Pro;
        text-align: center;
        letter-spacing: 4.4px;
        opacity: 1;
        color: #FFFFFF;
    }

    .ant-menu {
        background-color: $primary-color !important;

    }
}


.ant-drawer-header {
    background-color: $primary-color !important;
    border-bottom: none !important;
    box-shadow: 0px 1px 2px #000000A8;
}
