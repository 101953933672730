.items-center {
    align-items: center;
}
.flex {
    display: flex;
}
.margin-login {
    margin-top: 2.4rem;
}
.font-semibold {
    font-size: 26px;
    color: #6a6f7c;
    width: 100%;
}

.font-subtitle {
    font-size: 16px;
}

.mt-px {
    margin-top: 1px!important;
}
.divider{
    margin: 0px;
    flex-shrink: 0;
    border-width: 1px 1px thin;
    border-style: solid;
    border-color: #CCCFD5;
    width: 100%;
}

hr {
    height: 0;
    color: inherit;
}
hr {
    box-sizing: content-box;
    overflow: visible;
}

.textButton {
    align-items: center;
    display: flex;
    font-weight: 600;
    font-size: 20px;
}